import Vuex from "vuex";

export default new Vuex.Store({
  state: {
    aaa: "sdsgsd",
  },
  getters: {},
  mutations: {},
  actions: {},
  modules: {},
});
